import axios from 'axios'

export const getCategoriesAsync = (params) => axios.get('/categroy/findCategroy', { 
    params: params 
});

export const login = data => axios.post('/user/login', data);
export const monitorupdate = data => axios.post('/user/updateMonitor', data);



//查询是否含有这个医院
export const selectMeetingNameList = (params) => axios.get('/user/selectMeetingNameList', { 
    params: params 
});


export const monitorAdd = data => axios.post('/monitor/add', data);