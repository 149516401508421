import React from "react";
import PropTypes from 'prop-types';
import { Avatar } from "antd";


export default class UserInfo extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
            <div>
                <div className='rowLayout userItem' key={this.props.user.id}>
                    <Avatar size="small" icon="user"/>
                    <span className="lblUserName">
                        {this.props.user.name}
                    </span>
                </div>
            </div>
        );
    }
}

UserInfo.propTypes = {
    user: PropTypes.any.isRequired,
}