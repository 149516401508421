// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../image/112.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".meeting-layout{position:absolute;left:0px;right:0px;top:0px;bottom:0px;padding:0px;background-color:#323232}.meeting-layout-wating{left:0px;right:520px;top:20px;bottom:0px;display:flex;flex-direction:row;justify-content:center;align-items:center;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-position:top;background-size:84% auto;background-attachment:fixed;position:absolute}.meeting-local-video-layout{position:absolute;top:10px;right:35px;bottom:65px;width:360px;overflow-y:hidden;overflow-x:auto}", ""]);
// Exports
module.exports = exports;
