import React from 'react';
import { Modal, Button, Select,Tooltip,Icon } from 'antd';
import SoundMeter from './soundmeter';
import PropTypes from 'prop-types';
import SettingsIcon from "mdi-react/SettingsIcon";
import "../../styles/css/setting.scss";

const Option = Select.Option;

const closeMediaStream = function (stream) {
    if (!stream) {
        return;
    }
    if (MediaStreamTrack && MediaStreamTrack.prototype && MediaStreamTrack.prototype.stop) {
        var tracks, i, len;

        if (stream.getTracks) {
            tracks = stream.getTracks();
            for (i = 0, len = tracks.length; i < len; i += 1) {
                tracks[i].stop();
            }
        } else {
            tracks = stream.getAudioTracks();
            for (i = 0, len = tracks.length; i < len; i += 1) {
                tracks[i].stop();
            }

            tracks = stream.getVideoTracks();
            for (i = 0, len = tracks.length; i < len; i += 1) {
                tracks[i].stop();
            }
        }
        // Deprecated by the spec, but still in use.
    } else if (typeof stream.stop === 'function') {
        //console.log('closeMediaStream() | calling stop() on the MediaStream');
        stream.stop();
    }
}

// Attach a media stream to an element.
const attachMediaStream = function (element, stream) {
    element.srcObject = stream;
};

export default class MediaSettings extends React.Component {

    constructor(props) {
        super(props)
        let settings = props.settings;
        this.state = {
            visible: false,
            videoDevices: [],
            audioDevices: [],
            audioOutputDevices: [],
            resolution: settings.resolution,
            bandwidth: settings.bandwidth,
            selectedAudioDevice: settings.selectedAudioDevice,
            selectedVideoDevice: settings.selectedVideoDevice,
			selectedVideoDeviceLocal: settings.selectedVideoDeviceLocal,
            codec: settings.codec,
        }
        
        try {
            window.AudioContext = window.AudioContext || window.webkitAudioContext;
            window.audioContext = new AudioContext();
        } catch (e) {
            //console.log('Web Audio API not supported.');
        }
    }

    updateInputDevices = () => {
        return new Promise((pResolve, pReject) => {
            let videoDevices = [];
            let audioDevices = [];
            let audioOutputDevices = [];
            navigator.mediaDevices.enumerateDevices()
            .then((devices) => {
                for (let device of devices) {
                    if (device.kind === 'videoinput') {
                        videoDevices.push(device);
                    } else if (device.kind === 'audioinput') {
                        audioDevices.push(device);
                    }else if (device.kind === 'audiooutput'){
                        audioOutputDevices.push(device);
                    }
                }
            }).then(() => {
                let data = { videoDevices, audioDevices, audioOutputDevices};
                pResolve(data);
            });
        });
    }

    componentDidMount() {
        this.updateInputDevices().then((data) => {
			
			
            if (this.state.selectedAudioDevice === "" && data.audioDevices.length > 0) {
                this.state.selectedAudioDevice = data.audioDevices[0].deviceId;
            }
            if (this.state.selectedVideoDevice === "" && data.videoDevices.length > 0) {
				if(data.videoDevices.length>1){
					this.setState({ selectedVideoDevice: data.videoDevices[0].deviceId });
					this.setState({ selectedVideoDeviceLocal: data.videoDevices[1].deviceId });
					if(this.props.onMediaSettingsChanged !== undefined) {
					    this.props.onMediaSettingsChanged(
					        this.state.selectedAudioDevice,
					        this.state.selectedVideoDevice,
					        this.state.selectedVideoDeviceLocal,
					        this.state.resolution,
					        this.state.bandwidth,
					        this.state.codec);
					}	
				}else{
					this.setState({ selectedVideoDevice: data.videoDevices[0].deviceId });
					this.setState({ selectedVideoDeviceLocal: data.videoDevices[0].deviceId });
				}
				 
				 
				
				
            }
			//console.log(this.state.selectedVideoDevice)
			//console.log(this.state.selectedVideoDeviceLocal)
			//console.log(data.audioOutputDevices)		
            this.setState({
                videoDevices: data.videoDevices,
                audioDevices: data.audioDevices,
                audioOutputDevices: data.audioOutputDevices,
              
             })
			
			
            this.state.audioDevices.map((device, index) => {
                if (this.state.selectedAudioDevice == device.deviceId) {
                    //console.log("Selected audioDevice::" + JSON.stringify(device));
                }
            });
            this.state.videoDevices.map((device, index) => {
				 //console.log("Selected videoDevice::" + JSON.stringify(device));
                if (this.state.selectedVideoDevice == device.deviceId) {
                    //console.log("Selected videoDevice::" + JSON.stringify(device));
                }
            });
			let unStreamVideoDevice =data.videoDevices.some(item=>item.deviceId==this.state.selectedVideoDevice)
			let unStreamVideoDeviceLocal =data.videoDevices.some(item=>item.deviceId==this.state.selectedVideoDeviceLocal)
			if(!unStreamVideoDevice||!unStreamVideoDeviceLocal){
				
				if(data.videoDevices.length>1){
					this.setState({ selectedVideoDevice: data.videoDevices[0].deviceId });
					this.setState({ selectedVideoDeviceLocal: data.videoDevices[1].deviceId });
					if(this.props.onMediaSettingsChanged !== undefined) {
					    this.props.onMediaSettingsChanged(
					        this.state.selectedAudioDevice,
					        this.state.selectedVideoDevice,
					        this.state.selectedVideoDeviceLocal,
					        this.state.resolution,
					        this.state.bandwidth,
					        this.state.codec);
					}	
				}else{
					this.setState({ selectedVideoDevice: data.videoDevices[0].deviceId });
					this.setState({ selectedVideoDeviceLocal: data.videoDevices[0].deviceId });
					this.props.onMediaSettingsChanged(
					    this.state.selectedAudioDevice,
					    this.state.selectedVideoDevice,
					    this.state.selectedVideoDeviceLocal,
					    this.state.resolution,
					    this.state.bandwidth,
					    this.state.codec);
				}
			}
			
        });

    }

    soundMeterProcess = () => {
        var val = (window.soundMeter.instant.toFixed(2) * 348) + 1;
        this.setState({ audioLevel: val });
        if (this.state.visible)
            setTimeout(this.soundMeterProcess, 100);
    }
	soundMeterProcess2 = () => {
	    var val = (window.soundMeter.instant.toFixed(2) * 348) + 1;
	    this.setState({ audioLevel: val });
	    if (this.state.visible)
	        setTimeout(this.soundMeterProcess2, 100);
	}
    startPreview = () => {
        if (window.stream) {
            // closeMediaStream(window.stream);
        }
        let videoElement = this.refs['previewVideo'];
        let audioSource = this.state.selectedAudioDevice;
        let videoSource = this.state.selectedVideoDevice;
       
		
		sessionStorage.setItem('audioSource', audioSource);
		sessionStorage.setItem('videoSource', videoSource);
		
        this.soundMeter = window.soundMeter = new SoundMeter(window.audioContext);
        let soundMeterProcess = this.soundMeterProcess;
        let constraints = {
            audio: { deviceId: audioSource ? { exact: audioSource } : undefined },
            video: { deviceId: videoSource ? { exact: videoSource } : undefined }
        };
        navigator.mediaDevices.getUserMedia(constraints)
            .then(function (stream) {
                window.stream = stream; // make stream available to console
                //videoElement.srcObject = stream;
                attachMediaStream(videoElement, stream);
                soundMeter.connectToSource(stream);
                setTimeout(soundMeterProcess, 100);
                // Refresh button list in case labels have become available
                return navigator.mediaDevices.enumerateDevices();
            })
            .then((devces) => { })
            .catch((erro) => { });
    }
	startPreview2 = () => {
	    if (window.stream) {
	        // closeMediaStream(window.stream);
	    }
	  
	    let videoElement2 = this.refs['previewVideo2'];
	    let videoSource = this.state.selectedVideoDeviceLocal;
	    let selectedVideoDeviceLocal = this.state.selectedVideoDeviceLocal;
		sessionStorage.setItem('selectedVideoDeviceLocal', selectedVideoDeviceLocal);
	    this.soundMeter = window.soundMeter = new SoundMeter(window.audioContext);
	    let soundMeterProcess = this.soundMeterProcess2;
	    let constraints = {
	        video: { deviceId: videoSource ? { exact: videoSource } : undefined }
	    };
	    navigator.mediaDevices.getUserMedia(constraints)
	        .then(function (stream) {
	            window.stream = stream; // make stream available to console
	            //videoElement.srcObject = stream;
	           
	            attachMediaStream(videoElement2, stream);
	            soundMeter.connectToSource(stream);
	            setTimeout(soundMeterProcess, 100);
	            // Refresh button list in case labels have become available
	            return navigator.mediaDevices.enumerateDevices();
	        })
	        .then((devces) => { })
	        .catch((erro) => { });
	}
    stopPreview = () => {
        if (window.stream) {
            closeMediaStream(window.stream);
        }
    }

    componentWillUnmount() {

    }

    showModal = () => {
        this.setState({
            visible: true,
        });
        setTimeout(this.startPreview, 100);
        setTimeout(this.startPreview2, 100);
    }

    handleOk = (e) => {
        
        this.setState({
            visible: false,
        });
        this.stopPreview();
        if(this.props.onMediaSettingsChanged !== undefined) {
            this.props.onMediaSettingsChanged(
                this.state.selectedAudioDevice,
                this.state.selectedVideoDevice,
                this.state.selectedVideoDeviceLocal,
                this.state.resolution,
                this.state.bandwidth,
                this.state.codec);
        }
		history.go(0)

    }

    handleCancel = (e) => {
        this.setState({
            visible: false,
        });
        this.stopPreview();
    }

    handleAudioDeviceChange = (e) => {
        this.setState({ selectedAudioDevice: e });
        setTimeout(this.startPreview, 100);
    }

    handleVideoDeviceChange = (e) => {
        this.setState({ selectedVideoDevice: e });
        setTimeout(this.startPreview, 100);
    }
	handleVideoDeviceLocalChange = (e) => {
	    this.setState({ selectedVideoDeviceLocal: e });
	    setTimeout(this.startPreview2, 100);
	}
    handleResolutionChange = (e) => {
        this.setState({ resolution: e });
    }

    handleVideoCodeChange = (e) => {
        this.setState({ codec: e });
    }

    handleBandWidthChange = (e) => {
        this.setState({ bandwidth: e });
    }

    render() {
        return (
            <div>
                {
                    <Tooltip title='系统设置'>
                        <Button size="large" type="link" ghost onClick={this.showModal}>
                        <Icon
                            component={SettingsIcon}
                            style={{ display: "flex", justifyContent: "center" }}
                          />
						  <p	>系统设置</p>
                        </Button>
                    </Tooltip>
                }
                <Modal
                    title='系统设置'
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    okText='确定'
                    cancelText='取消'>
                    <div className="settings-item">
                        <span className="settings-item-left">麦克风</span>
                        <div className="settings-item-right">
                            <Select value={this.state.selectedAudioDevice} style={{ width: 350 }} onChange={this.handleAudioDeviceChange}>
                                {
                                    this.state.audioDevices.map((device, index) => {
                                        return (<Option value={device.deviceId} key={device.deviceId}>{device.label}</Option>);
                                    })
                                }
                            </Select>
                            <div ref="progressbar" style={{
                                width: this.state.audioLevel + 'px',
                                height: '10px',
                                backgroundColor: '#8dc63f',
                                marginTop: '20px',
                            }}>
                            </div>
                        </div>
                    </div>
                    <div className="settings-item">
                        <span className="settings-item-left">摄像头1</span>
                        <div className="settings-item-right">
                            <Select value={this.state.selectedVideoDevice} style={{ width: 350 }} onChange={this.handleVideoDeviceChange}>
                                {
                                    this.state.videoDevices.map((device, index) => {
                                        return (<Option value={device.deviceId} key={device.deviceId}>{device.label}</Option>);
                                    })
                                }
                            </Select>
                            <div className="settings-video-container">
                                <video id='previewVideo' ref='previewVideo' autoPlay playsInline muted={true} style={{ width: '100%', height: '100%', objectFit: 'contain' }}></video>
                            </div>

                        </div>
                    </div>
					<div className="settings-item">
					    <span className="settings-item-left">摄像头2</span>
					    <div className="settings-item-right">
					        <Select value={this.state.selectedVideoDeviceLocal} style={{ width: 350 }} onChange={this.handleVideoDeviceLocalChange}>
					            {
					                this.state.videoDevices.map((device, index) => {
					                    return (<Option value={device.deviceId} key={device.deviceId}>{device.label}</Option>);
					                })
					            }
					        </Select>
					        <div className="settings-video-container">
					            <video id='previewVideo1' ref='previewVideo2' autoPlay playsInline muted={true} style={{ width: '100%', height: '100%', objectFit: 'contain' }}></video>
					        </div>
					
					    </div>
					</div>
                   
                </Modal>
            </div>
        );
    }
}


MediaSettings.propTypes = {
    onMediaSettingsChanged: PropTypes.func
}


 // <div className="settings-item">
 //                        <span className="settings-item-left">分辨率</span>
 //                        <div className="settings-item-right">
 //                            <Select style={{ width: 350 }} value={this.state.resolution} onChange={this.handleResolutionChange}>
 //                                <Option value="qvga">QVGA(320x180)</Option>
 //                                <Option value="vga">VGA(640x360)</Option>
 //                                <Option value="shd">SHD(960x540)</Option>
 //                                <Option value="hd">HD(1280x720)</Option>
 //                                <Option value="1k">1K(1920x1080)</Option>
 //                                <Option value="2K">2K(3840x2160)</Option>
 //                                <Option value="4K">4K(5760x3240)</Option>
 //                            </Select>
 //                        </div>
 //                    </div>
 //                    <div className="settings-item">
 //                        <span className="settings-item-left">视频编码</span>
 //                        <div className="settings-item-right">
 //                            <Select style={{ width: 350 }} value={this.state.codec} onChange={this.handleVideoCodeChange}>
 //                                <Option value="h264">H264</Option>
 //                                <Option value="vp8">VP8</Option>
 //                                <Option value="vp9">VP9</Option>
 //                            </Select>
 //                        </div>
 //                    </div>
 //                    <div className="settings-item">
 //                        <span className="settings-item-left">带宽</span>
 //                        <div className="settings-item-right">
 //                            <Select style={{ width: 350 }} value={this.state.bandwidth} onChange={this.handleBandWidthChange}>
 //                                <Option value="256">低清(256kbps)</Option>
 //                                <Option value="512">普清(512kbps)</Option>
 //                                <Option value="1024">高清(1Mbps)</Option>
 //                                <Option value="4096">超高清(4Mbps)</Option>
 //                            </Select>
 //                        </div>
 //                    </div>