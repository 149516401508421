import React from "react";
import { Form, Icon, Input, Button, Card } from "antd";
import { reactLocalStorage } from "reactjs-localstorage";
import "../styles/css/login.scss";
import { Route } from "react-router/cjs/react-router.min";
import { selectMeetingNameList, monitorAdd } from "./api/categories";

class UserLogin extends React.Component {
	constructor() {
		super();
		this.state = {
			login: false,
			loading: false,
			collapsed: false,
			loginInfo: {},
			uid: '',
			roomId: '',
			name: '',
			users: [],
			messages: [],
			localAudioEnabled: true,
			localVideoEnabled: true,
			screenSharingEnabled: false,
			vidFit: false,
			brush: false,
			isFullScreen: false,
			FieldsValue: {
				'roomId': "",
				'displayName': "",
				'displayPassword': ""
			},
			MeetingList: [],

		};
		this.getSelectMeetingNameList()
		this.showNavigator()

	}
	showNavigator() {
		const constraints = window.constraints = {
			//禁用音频
			audio: true,
			//启用视频
			video: true
		};
		navigator.mediaDevices.getUserMedia(constraints)
			.then(function (stream) {
				console.log("请求成功")
			})
			.then((devces) => { console.log("请求成功") })
			.catch((erro) => { console.log("请求失败") });
	}
	getSelectMeetingNameList = async () => {



		const res = await selectMeetingNameList({
			userId: sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : 25
		});
		console.log(res);
		if (res.code == "200") {
			this.setState({
				MeetingList: res.data
			})

		} else {
			alert("查询失败");
		}
	}
	ToMain = async (msg,roomName) => {
		const res = await monitorAdd({
			userId: sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : 25,
			userName: sessionStorage.getItem('userName') ? sessionStorage.getItem('userId') : "hw1",
			roomId: msg ? msg : "80001"
		});
		console.log(res);
		if (res.code == "200") {
			sessionStorage.setItem("monitorId", res.data);
			sessionStorage.setItem("toMeetingTime", new Date().getTime());
			sessionStorage.setItem('roomId', msg);
			sessionStorage.setItem('roomName', roomName);
			this.props.history.push("/Samples")
		} else {
			alert("查询失败");
		}

	}
	handleSubmit = e => {
		e.preventDefault();
		// this.props.form.validateFields((err, values) => {
		//   if (!err) {
		//     const handleLogin = this.props.handleLogin;
		//     handleLogin(values);
		//     console.log("登录表单里的值为: ", values);
		//   }
		// });

		// this.props.history.push("/Samples")


	};

	// getRequest() {
	//   let url = location.search;
	//   let theRequest = new Object();
	//   if (url.indexOf("?") != -1) {
	//     let str = url.substr(1);
	//     let strs = str.split("&");
	//     for (let i = 0; i < strs.length; i++) {
	//       theRequest[strs[i].split("=")[0]] = decodeURI(strs[i].split("=")[1]);
	//     }
	//   }
	//   return theRequest;
	// }

	render() {
		const { getFieldDecorator } = this.state

		return (
			<div className="">
				<div className="div-show1"> 医院列表</div>
				<div className="div-show3">

					{
						this.state.MeetingList.map((item, index) => {
							return index >= 0 ? (
								<div className="div-show" key={item.id} id={item.id} onClick={() => this.ToMain(item.id,item.meetingName)}>{item.meetingName}</div>
							) : (
								""
							);
						})}
				</div>


			</div>
		);
	}
}

// const WrappedLoginForm = Form.create({ name: "login" })(UserLogin);
// export default WrappedLoginForm;


export default UserLogin;


// <div  className="div-show" onClick={() =>this.ToMain("admin805")   }>第七医学中心</div>
			// <div  className="div-show" onClick={() =>this.ToMain("深圳第一人民医院")   }>深圳第一人民医院</div>
			// <div  className="div-show" onClick={() =>this.ToMain("深圳第二人民医院")   }>深圳第二人民医院</div>
			// <div  className="div-show" onClick={() =>this.ToMain("深圳第三人民医院")   }>深圳第三人民医院</div>