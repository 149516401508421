import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import {
    Modal,
    Button,
    Tooltip,
    Input,
    Icon,
    Radio,
    Popover,
    Menu,
    Avatar,
  } from "antd";
import BrushOffIcon from "mdi-react/BrushIcon";

export default class PenMeeting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      value: 1,
      current1: "2",
      current2: "black",
    };
  }
  onChange = (e) => {
    console.log("radio checked", e.target.value);
    this.setState({
      value: e.target.value,
    });
  };
  showModal = () => {
    this.setState({
      visible: true,
    });

    let url = this.props.pen;
    // let host = window.location.host;
    // let url = window.location.protocol + "//" + host + "/?room=" + loginInfo.roomId;
    this.setState({ url });
  };
  handleOk = (e) => {
    this.setState({
      visible: false,
    });
    let aa = ["red", "Green", "Black", "White"];
    // console.log(aa[this.state.value-1])
    sessionStorage.setItem("color", aa[this.state.value - 1]);
    console.log(sessionStorage.getItem("color"));
    // this.props.changeColor(aa[this.state.value-1])
    // this.props.changeColor(aa[this.state.value-1])
  };
  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  onFocus = (e) => {
    ReactDOM.findDOMNode(e.target).select();
  };
  handleClick1 = (e) => {
    sessionStorage.setItem("pensize", e.key);
    this.setState({
      current1: e.key,
    });
  };
  handleClick2 = (e) => {
    
    sessionStorage.setItem("color", e.key);
    this.setState({
      current2: e.key,
    });
  };
  render() {
    const content = (
      <div>
        <Menu
          onClick={this.handleClick1}
          selectedKeys={[this.state.current1]}
          mode="horizontal"
        >
          <Menu.Item key="2">
            <Icon
              type="line"
              style={{ fontSize: "30px", }}
            />
          </Menu.Item>
          <Menu.Item key="4">
            <Icon
              type="line"
              style={{
                fontSize: "40px",
              }}
            />
          </Menu.Item>
          <Menu.Item key="8">
          <Icon
              type="line"
              style={{ fontSize: "50px",  }}
            />
          </Menu.Item>
          <Menu.Item key="16">
          <Icon
              type="line"
              style={{ fontSize: "60px",}}
            />
          </Menu.Item>
        </Menu>
        <br />
        <Menu
          onClick={this.handleClick2}
          selectedKeys={[this.state.current2]}
          mode="horizontal"
        >
          <Menu.Item key="black">
            <Avatar
              style={{ color: "#f56a00", backgroundColor: "#000000" }}
            ></Avatar>
          </Menu.Item>

          <Menu.Item key="red">
            <Avatar
              style={{ color: "#f56a00", backgroundColor: "#e31e1f" }}
            ></Avatar>
          </Menu.Item>
          <Menu.Item key="orange">
            <Avatar
              style={{ color: "#f56a00", backgroundColor: "#f76509" }}
            ></Avatar>
          </Menu.Item>
          <Menu.Item key="yellow">
            <Avatar
              style={{ color: "#f56a00", backgroundColor: "#f7ba12" }}
            ></Avatar>
          </Menu.Item>
          <Menu.Item key="green">
            <Avatar
              style={{ color: "#f56a00", backgroundColor: "#09be55" }}
            ></Avatar>
          </Menu.Item>
          <Menu.Item key="cyan">
            <Avatar
              style={{ color: "#f56a00", backgroundColor: "#32dab3" }}
            ></Avatar>
          </Menu.Item>
          <Menu.Item key="blue">
            <Avatar
              style={{ color: "#f56a00", backgroundColor: "#056ff9" }}
            ></Avatar>
          </Menu.Item>
          <Menu.Item key="purple">
            <Avatar
              style={{ color: "#f56a00", backgroundColor: "#6136f8" }}
            ></Avatar>
          </Menu.Item>
        </Menu>
      </div>
    );
    return (
      <div className="app-header-tool-container">
        <Tooltip >
          <Popover content={content}>
            <Button ghost size="large" type="link" >
              <Icon
                component={this.state.Brush ? BrushIcon : BrushOffIcon}
                style={{ display: "flex", justifyContent: "center" }}
              />
              <p>画笔</p>
            </Button>
          </Popover>
        </Tooltip>
        <Modal
          title="选择颜色"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          okText="确定"
          cancelText="取消"
        >
          <div>
            <div>
              <Radio.Group onChange={this.onChange} value={this.state.value}>
                <Radio value={1}>
                  <span>红色</span>{" "}
                  <p
                    style={{
                      width: "20px",
                      height: "20px",
                      background: "red",
                      display: "inline",
                    }}
                  ></p>
                </Radio>
                <Radio value={2}>
                  <span>绿色</span>{" "}
                  <span
                    style={{ width: "20px", height: "20px", background: "red" }}
                  ></span>
                </Radio>
                <Radio value={3}>
                  <span>黑色</span>{" "}
                  <span
                    style={{ width: "20px", height: "20px", background: "red" }}
                  ></span>
                </Radio>
                <Radio value={4}>
                  <span>白色</span>{" "}
                  <span
                    style={{ width: "20px", height: "20px", background: "red" }}
                  ></span>
                </Radio>
              </Radio.Group>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

PenMeeting.propTypes = {
  roomInfo: PropTypes.any,
};
