import 'babel-polyfill'
import React from "react";
import { HashRouter as Router,Route,} from 'react-router-dom';

import Samples from './Samples';
import RoomList from './RoomList';
import UserLogin from './UserLogin';


//主组件
class App extends React.Component {

  render() {
    //路由配置
    return <Router>
           
                {/* 首页 */}
				
				<Route exact path="/" component={UserLogin} />
				<Route exact path="/RoomList" component={RoomList} />
                <Route exact path="/Samples" component={Samples} />
                
              
			  
          
        </Router>
  }
}
//导出主组件
export default App;
